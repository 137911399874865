
import clock from './clock.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={clock}  alt="logo" />
        <p>
          Something exciting is Coming Soon! It's ChainTix!
        </p>
      </header>
    </div>
  );
}

export default App;
